:root {
  --LA1BackgroundColor: rgb(0, 0, 0);
  --LA1LoadingDotsColor: rgb(255, 255, 255);
  --MainAnimationDuration: 5s;
}

main.LA1trick {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: var(--LA1BackgroundColor);
  overflow: hidden;
}

.LA1loaders {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: fit-content;
  -webkit-box-reflect: below 0
    linear-gradient(transparent, transparent, transparent, rgba(0, 0, 0, 0.25));
}

.LA1loader1,
.LA1loader2,
.LA1loader3 {
  background-color: var(--LA1LoadingDotsColor);
  width: 200px;
  height: 200px;
  border-radius: 50%;
  opacity: 0;
  transform: translateY(-150px);
}

.LA1loader1 {
  animation: LA1Loading var(--MainAnimationDuration) linear 2s infinite;
}

.LA1loader2 {
  margin: 0 25px;
  animation: LA1Loading var(--MainAnimationDuration) linear 2.5s infinite;
}

.LA1loader3 {
  animation: LA1Loading var(--MainAnimationDuration) linear 3s infinite;
}

@media (max-width: 670px) {
  .LA1loader1,
  .LA1loader2,
  .LA1loader3 {
    width: 125px;
    height: 125px;
  }
}

@media (max-width: 400px) {
  .LA1loader1,
  .LA1loader2,
  .LA1loader3 {
    width: 75px;
    height: 75px;
  }
}

@keyframes LA1Loading {
  0% {
    opacity: 0;
    transform: translateY(-150px);
  }
  10% {
    transform: translateY(0);
  }
  30% {
    opacity: 1;
  }
  70% {
    opacity: 1;
    transform: translateY(0);
  }
  80% {
    opacity: 0;
    transform: translateY(-150px);
  }
  100% {
    opacity: 0;
    transform: translateY(-150px);
  }
}
