.text1 {
  font-size: 2.5rem;
  color: var(--whiteText);
  font-weight: 500;
}

.text2 {
  margin: 30px 0 20px 0;
  font-size: 1.3rem;
  color: var(--whiteText);
  font-weight: 100;
}

.text2 span {
  font-size: 1.5rem;
  font-weight: 700;
  text-transform: uppercase;
}

.startbtnHolder {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
}

.startbtnHolder .typicalbtn {
  margin-left: 80px;
}

@media (max-width: 1200px) {
  .text1 {
    font-size: 2.1rem;
  }
  .text2 {
    font-size: 1.25rem;
  }
  .text2 span {
    font-size: 1.5rem;
  }
  .startbtnHolder .typicalbtn {
    margin-left: 40px;
  }
}

@media (max-width: 900px) {
  .startbtnHolder {
    justify-content: center;
  }
  .startbtnHolder .typicalbtn {
    margin-left: 0;
  }
}

@media (max-width: 730px) {
  .text1 {
    font-size: 1.9rem;
  }
  .text2 {
    font-size: 1.2rem;
  }
}

@media (max-width: 500px) {
  .text2 {
    font-size: 1.1rem;
  }
}
