.presenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background-color: var(--presenterColor);
  transition: var(--transition);
  z-index: 10;
}

.empty {
  width: 150vw;
  height: 150vw;
  animation: Intro 1.5s forwards;
}

.fill {
  width: 0;
  height: 0;
  animation: Outro 1.5s forwards;
}

@keyframes Intro {
  0% {
    height: 150vw;
    width: 150vw;
  }
  100% {
    height: 0;
    width: 0;
  }
}

@keyframes Outro {
  0% {
    height: 0;
    width: 0;
  }
  100% {
    height: 150vw;
    width: 150vw;
  }
}
