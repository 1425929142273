body {
  overflow-y: auto !important;
}

.listholder {
  position: relative;
  background-color: rgb(26, 26, 58);
  width: 100vw;
  min-height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  padding-bottom: 50px;
}

.listTitle {
  font-size: 5rem;
  color: white;
  margin-bottom: 60px;
}

@media (max-width: 550px) {
  .listTitle {
    font-size: 4rem;
    color: white;
    margin-bottom: 30px;
  }
}

@media (max-width: 450px) {
  .listTitle {
    font-size: 3.5rem;
    color: white;
    margin-bottom: 30px;
  }
}
