.right,
.left,
.top {
  position: absolute;
  top: 0;
  background-color: transparent;
}

.right,
.left {
  height: 100%;
  width: 15vw;
  z-index: 20;
}

.right {
  right: 0;
}

.left {
  left: 0;
}

.top {
  height: 20vh;
  width: 100vw;
  z-index: 19;
}
