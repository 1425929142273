* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: sans-serif;
  overflow: hidden;
  font-size: 10px;
}

.container {
  padding: 10px;
}

a,
button {
  cursor: pointer;
}

a {
  text-decoration: none;
}
