:root {
  --BA1BorderColor1: rgb(254, 5, 5);
  --BA1BorderColor2: rgb(0, 26, 255);
  --BA1BackgroundColor: rgb(19, 2, 37);
  --BA1TrackColor: rgba(0, 0, 0, 0.412);
}

main.BA1trick {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: var(--BA1BackgroundColor);
  overflow: hidden;
}

.BA1holder1,
.BA1holder2,
.BA1holder3,
.BA1holder4,
.BA1holder5,
.BA1holder6,
.BA1holder7,
.BA1holder8,
.BA1holder9,
.BA1holder10 {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  background-color: var(--BA1TrackColor);
  overflow: hidden;
  border-radius: 20px;
}

.BA1holder1::before,
.BA1holder2::before,
.BA1holder3::before,
.BA1holder4::before,
.BA1holder5::before,
.BA1holder6::before,
.BA1holder7::before,
.BA1holder8::before,
.BA1holder9::before,
.BA1holder10::before {
  content: "";
  position: absolute;
  background: linear-gradient(
    var(--BA1BorderColor1) 50%,
    var(--BA1BorderColor2) 50%
  );
  animation: BA1BorderAnimation 3s linear infinite,
    BA1HueRotate 6s infinite forwards;
}

.BA1holder1::after,
.BA1holder2::after,
.BA1holder3::after,
.BA1holder4::after,
.BA1holder5::after,
.BA1holder6::after,
.BA1holder7::after,
.BA1holder8::after,
.BA1holder9::after,
.BA1holder10::after {
  content: "";
  position: absolute;
  inset: 4px;
  background-color: var(--BA1BackgroundColor);
  border-radius: 16px;
}

.BA1holder1 {
  width: max-content;
  height: 300px;
  padding: 20px;
  text-align: center;
}

.BA1holder1::before {
  width: 150px;
  height: 200%;
}

.BA1holder1 h1 {
  opacity: 0.5;
  font-size: 2.5rem;
  z-index: 2;
  color: rgb(255, 255, 255);
  animation: BA1ShowText 3s ease-in-out 500ms forwards;
}

.BA1holder2 {
  top: -20px;
  left: -20px;
  width: 300px;
  height: 300px;
}

.BA1holder2::before {
  width: 150px;
  height: 200%;
}

.BA1holder3 {
  bottom: -20px;
  right: -20px;
  width: 300px;
  height: 300px;
}

.BA1holder3::before {
  width: 150px;
  height: 200%;
}

.BA1holder4 {
  top: -30px;
  left: -20px;
  width: 300px;
  height: 300px;
}

.BA1holder4::before {
  width: 150px;
  height: 200%;
}

.BA1holder5 {
  top: -40px;
  left: -20px;
  width: 300px;
  height: 300px;
}

.BA1holder5::before {
  width: 150px;
  height: 200%;
}

.BA1holder6 {
  top: -50px;
  left: -20px;
  width: 300px;
  height: 300px;
}

.BA1holder6::before {
  width: 150px;
  height: 200%;
}

.BA1holder7 {
  top: -60px;
  left: -20px;
  width: 300px;
  height: 300px;
}

.BA1holder7::before {
  width: 150px;
  height: 200%;
}

.BA1holder8 {
  bottom: -70px;
  left: -20px;
  width: 700px;
  height: 200px;
}

.BA1holder8::before {
  width: 150px;
  height: 400%;
}

.BA1holder9 {
  bottom: -70px;
  left: -85px;
  width: 700px;
  height: 200px;
}

.BA1holder9::before {
  width: 150px;
  height: 400%;
}

.BA1holder10 {
  top: -100px;
  right: -100px;
  width: 300px;
  height: 300px;
  border-radius: 50%;
}

.BA1holder10::before {
  width: 150px;
  height: 200%;
}

.BA1holder10::after {
  border-radius: 50%;
}

@media (max-width: 975px) {
  .BA1holder8 {
    left: -200px;
  }

  .BA1holder9 {
    left: -265px;
  }
}

@media (max-width: 800px) {
  .BA1holder8 {
    left: -400px;
  }

  .BA1holder9 {
    left: -465px;
  }
}

@media (max-width: 500px) {
  h1 {
    font-size: 1.5rem;
  }

  .BA1holder1 {
    height: 150px;
  }

  .BA1holder1::before {
    width: 75px;
  }

  .BA1holder2 {
    left: -150px;
  }

  .BA1holder4,
  .BA1holder5,
  .BA1holder6,
  .BA1holder7 {
    left: -150px;
  }

  .BA1holder8 {
    left: -550px;
  }

  .BA1holder9 {
    left: -615px;
  }
}

@keyframes BA1BorderAnimation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@keyframes BA1ShowText {
  from {
    opacity: 0;
    scale: 0;
  }
  to {
    opacity: 1;
    scale: 1;
  }
}

@keyframes BA1HueRotate {
  from {
    filter: hue-rotate(0deg);
  }
  to {
    filter: hue-rotate(359deg);
  }
}
