.homeMain {
  position: relative;
  width: 100vw;
  height: 100vh;
  text-align: center;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: url("/public/images/background-medium.jpg");
  background-size: cover;
  filter: brightness(0.7);
  animation: BackgroundAnimation 12s infinite forwards;
}

.moonholder {
  position: absolute;
  top: 0;
  right: 0;
  width: 40vw;
  height: 40vh;
  margin-top: 15vh;
  transition: var(--transition);
}

.mooncontainer {
  width: 100%;
  height: 100%;
}

.moon {
  height: 100%;
  width: fit-content;
  margin: auto;
  border-radius: 50%;
  overflow: hidden;
  transition: var(--transition);
  animation: MoonAnimation1 12s infinite forwards;
}

.moon img {
  width: auto;
  max-height: 100%;
  height: auto;
  transition: var(--transition);
}

.moon:hover {
  animation: MoonAnimation2 12s infinite forwards;
}

.moon:hover img {
  filter: invert(1);
}

.container {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  width: 50vw;
  margin: 0 0 0 50px;
}

header {
  margin-top: 10vh;
  margin-bottom: 5vh;
}

.title {
  width: fit-content;
  font-size: 7rem;
  color: var(--whiteText);
  transition: var(--transition);
}

.homeMain footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  text-align: center;
}

.homeMain footer h3 {
  font-size: 1rem;
  color: var(--whiteText);
}

.homeMain footer p {
  font-size: 1rem;
  color: var(--whiteText);
}

@media (max-width: 1200px) {
  .title {
    font-size: 5.5rem;
  }
}

@media (max-width: 900px) {
  .moonholder {
    display: none;
  }
  .container {
    justify-content: center;
    text-align: center;
    width: 90vw;
  }
}

@media (max-width: 730px) {
  .title {
    font-size: 4rem;
  }
  .container {
    margin: 0 0 0 20px;
  }
}

@media (max-width: 500px) {
  header {
    margin-top: 9vh;
  }
}

@keyframes BackgroundAnimation {
  0%,
  100% {
    filter: contrast(1);
  }
  50% {
    filter: contrast(2);
  }
}

@keyframes MoonAnimation1 {
  0%,
  100% {
    filter: contrast(0.8) drop-shadow(0 0 0 red);
  }
  50% {
    filter: contrast(1.3) drop-shadow(0 0 25px red);
  }
}

@keyframes MoonAnimation2 {
  0%,
  100% {
    filter: contrast(0.8) drop-shadow(0 0 0 blue);
  }
  50% {
    filter: contrast(1.3) drop-shadow(0 0 25px blue);
  }
}
